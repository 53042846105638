import { Helmet } from "react-helmet";

const PageHead = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta charset="UTF-8" />
    {description ? <meta name="description" content={description} /> : null}
  </Helmet>
);

export default PageHead;
