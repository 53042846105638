import { useEffect, useState } from "react";
import { Layout } from "antd";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Calendar from "./Calendar";

import PageHead from "@components/core/modules/PageHead";
import Spinner from "@components/core/atoms/Spinner";
import FootballAPI from "@api/football.api";
import CompetitionFixturesTable from "./CompetitionFixturesTable";
import DrawerCalendarDatePicker from "./DrawerCalendarDatePicker";
import useMediaScreen from "utils/hooks/useMediaScreen";
import CompetitionMenu from "pages/SearchPage/CompetitionMenu";

const MOMENT_DATE_FORMAT = "yyyy-MM-DD";

const convertStringDateToMoment = (strDate) => {
  let startDay = moment();

  if (strDate) {
    startDay = moment(strDate);

    if (!moment().isValid(startDay)) {
      startDay = moment();
    }
  }

  return startDay;
};

function FixturesCalendar() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { lang } = useParams();
  const [searchParams] = useSearchParams();
  const { isLarge, isMedium } = useMediaScreen();

  const dateParams = searchParams.get("date");
  const convertedSelectedDay = convertStringDateToMoment(dateParams);
  const convertedStartDay = moment(convertedSelectedDay).subtract(1, "days");

  const [fixtures, setFixtures] = useState([]);
  const [startDay, setStartDay] = useState(
    convertedStartDay.format("YYYY-MM-DD")
  );
  const [selectedDays, setSelectedDays] = useState([convertedSelectedDay]);
  const [isLoadingFixtures, setLoadingFixtures] = useState(false);

  const [shouldOpen, setShouldOpen] = useState(false);

  const fetchFixtures = async (date) => {
    setLoadingFixtures(true);
    let result = await FootballAPI.getFixturesByDate(date, lang);

    setFixtures(result);
    setLoadingFixtures(false);
  };

  const onSelectDay = (days) => {
    navigate(`/${lang}?date=${days[0]}`, {
      replace: true,
    });
  };

  useEffect(() => {
    const date = convertStringDateToMoment(dateParams);

    setSelectedDays([date]);
    fetchFixtures(date.format(MOMENT_DATE_FORMAT));
    window.scrollTo(0, 0);
  }, [dateParams]);

  return (
    <Layout style={styles.container}>
      <PageHead title={t("fixture_calendar.title")} description={""} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          paddingTop: isLarge ? 40 : 0,
          margin: "auto",
          paddingBottom: 30,
          width: "100%",
        }}
      >
        {isLarge ? (
          <div
            style={{
              width: "23%",
              minWidth: 280,
              maxWidth: 400,
              marginInline: 20,
              marginLeft: 0,
            }}
          >
            <CompetitionMenu theme="dark" />
          </div>
        ) : null}

        <div
          style={{
            width: isLarge ? "45%" : "100%",
            minWidth: isLarge ? 600 : 0,
          }}
        >
          <div
            style={{
              maxWidth: isLarge ? "700px" : null,
              border: isLarge ? "1px solid #e9e9e9" : null,
              borderRadius: 10,
            }}
          >
            <Calendar
              startDay={startDay}
              selectedDays={selectedDays}
              selectDay={onSelectDay}
              openCalendar={() => setShouldOpen(true)}
              mobileRightExtra
            />
          </div>

          {isLoadingFixtures ? (
            <div style={styles.spinner.container}>
              <Spinner />
            </div>
          ) : null}

          {!isLoadingFixtures && fixtures.length ? (
            <div
              style={{
                ...styles.content,
                marginTop: 20,
                paddingInline: isLarge ? 0 : isMedium ? 20 : 10,
                maxWidth: isLarge ? "700px" : null,
              }}
            >
              {fixtures.map((groupedFixtures) => (
                <div key={groupedFixtures.title}>
                  <CompetitionFixturesTable fixtures={groupedFixtures.items} />
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>

      <DrawerCalendarDatePicker
        placement="bottom"
        onClose={() => setShouldOpen(false)}
        visible={shouldOpen}
        onSelect={(value) => {
          setStartDay(value.clone().subtract(1, "days"));
          onSelectDay([value.clone().format("YYYY-MM-DD")]);
        }}
      />
    </Layout>
  );
}

const styles = {
  container: {
    width: "100%",
    background: "#f8f9fa",
  },
  content: {
    width: "100%",
    maxWidth: "1200px",
  },
  subtitle: {
    color: "#848292",
    fontSize: "min(max(15px, 1.5vw), 16px)",
  },
  spinner: {
    container: {
      height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
};

export default FixturesCalendar;
